import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { QueryPartners } from "./QueryPartners";
import { ViewPartner } from "./ViewPartner";

export const Partners = () => {
  const { path } = useRouteMatch();
  const title = "Partners";
  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <QueryPartners title={title} />
      </Route>
      <Route exact path={`${path}/:id`}>
        <ViewPartner title="Partner" />
      </Route>
    </Switch>
  );
};
