import { makeStyles } from "tss-react/mui";
import { Box, Link } from "@mui/material";
import React from "react";
import { BooleanExceptZero } from "../helpers/BooleanExceptZero";

type Colors = "success" | "error" | "primary";

type Props = {
  label: string;
  value?: string | number | boolean | null;
  onValueClick?: () => any;
  children?: React.ReactNode;
  colorValue?: Colors;
};

const useStyles = (colorValue?: Colors) =>
  makeStyles()((theme) => ({
    label: {
      width: 350,
      fontWeight: 600,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    value: {
      color: colorValue ? theme.palette[colorValue].main : undefined,
    },
    control: {
      marginLeft: "auto",
    },
  }))();

export const EditableField = ({
  label,
  value,
  onValueClick,
  children,
  colorValue,
}: Props) => {
  const renderValue = () => {
    if (typeof value === "boolean") {
      return null;
    }

    const displayValue = BooleanExceptZero(value) ? value : "N/A";
    if (onValueClick) {
      return (
        <Link
          className={classes.value}
          component="button"
          onClick={onValueClick}
        >
          {displayValue}
        </Link>
      );
    }
    return <span className={classes.value}>{displayValue}</span>;
  };

  const { classes } = useStyles(colorValue);
  return (
    <Box display="flex" width="100%" alignItems="center">
      <div className={classes.label}>{label}</div>
      {renderValue()}
      {children && <div className={classes.control}>{children}</div>}
    </Box>
  );
};
