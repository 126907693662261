import { LoaderButton } from "../../../shared/components/LoaderButton";
import { makeStyles } from "tss-react/mui";

import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { toDateString } from "../../../shared/helpers/dates";
import { UploadTemplateAmendmentsFormState } from "../../../store/uploadTemplateAmendments/types";

const useStyles = makeStyles()((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
  },
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  open: boolean;
  isSubmitting: boolean;
  message?: string;
  onSubmit: (formValues: UploadTemplateAmendmentsFormState) => void;
  onCancel: () => void;
};

export const ModalUploadAssetFinancials = (props: Props) => {
  const { classes } = useStyles();

  const [formValues, setFormValues] = useState<
    UploadTemplateAmendmentsFormState
  >({
    journalsOnly: false,
    postingDate: toDateString(new Date()),
    serviceProviderId: undefined,
    unallocatedPosting: false,
  });

  return (
    <Dialog open={props.open}>
      <Card className={classes.card}>
        <Box mt={2} mb={4}>
          {/* <Typography variant="h2" align="center" gutterBottom>
          </Typography> */}
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Box mb={2}>
              <CustomStatusChip type="info" message={props.message} />
            </Box>
            <FormFieldsWrapper>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  size="small"
                  variant="outlined"
                  id="select-provider"
                  select
                  label="Select Service Provider"
                  value={
                    formValues.serviceProviderId !== undefined
                      ? serviceProviders[formValues.serviceProviderId - 1]
                      : undefined
                  }
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    const serviceProvider = event.target
                      .value as ServiceProvider;
                    setFormValues({
                      ...formValues,
                      serviceProviderId: getServiceProviderId(serviceProvider),
                    });
                  }}
                >
                  {serviceProviders.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl variant="outlined" size="small" fullWidth>
                <DatePicker
                  label="Posting/Document Date"
                  format="dd/MM/yyyy"
                  value={
                    !!formValues.postingDate ? new Date(formValues.postingDate) : null
                  }
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      postingDate: !!event ? toDateString(event) : undefined,
                    })
                  }
                />
              </FormControl>
              <FormControl variant="outlined" size="small" fullWidth>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    color="primary"
                    checked={formValues.journalsOnly}
                    onClick={() => {
                      setFormValues({
                        ...formValues,
                        journalsOnly: !formValues.journalsOnly,
                      });
                    }}
                  />
                  <Typography>Journals only</Typography>
                </Box>
              </FormControl>
              <FormControl variant="outlined" size="small" fullWidth>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    color="primary"
                    checked={formValues.unallocatedPosting}
                    onClick={() => {
                      setFormValues({
                        ...formValues,
                        unallocatedPosting: !formValues.unallocatedPosting,
                      });
                    }}
                  />
                  <Typography>Unallocated Posting</Typography>
                </Box>
              </FormControl>
            </FormFieldsWrapper>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row-reverse">
          <LoaderButton
            size="large"
            loading={props.isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
            onClick={() => props.onSubmit(formValues)}
          >
            Submit
          </LoaderButton>
          <Button
            disabled={props.isSubmitting}
            size="large"
            variant="outlined"
            onClick={props.onCancel}
            className={classes.buttonCancel}
          >
            Cancel
          </Button>
        </Box>
      </Card>
    </Dialog>
  );
};
