import { Button, Checkbox, List, ListItem, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { RequestError } from "../../../api/types";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { Page } from "../../../layout/components/Page";
import { APIFetchingWrapper } from "../../../shared/components/APIFetchingWrapper";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { FileDrop } from "../../../shared/components/FileDrop";
import { getImportProductsSchema } from "./formSchema";
import { postGenerateFromTariffModel } from "../../../api/product-csv/generate-from-tariff-model";
import FileDownload from "js-file-download";

type Props = { title: string };
export type GenerateParams = {
  primary: boolean;
  secondary: boolean;
  spendCaps: boolean;
};

const defaultGenerateParams: GenerateParams = {
  primary: false,
  secondary: false,
  spendCaps: false,
};

export const GenerateProducts = ({ title }: Props) => {
  const schemaImport = useMemo(() => getImportProductsSchema(), []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [apiError, setApiError] = useState<RequestError | undefined>();
  const [generateParams, setGenerateParams] = useState<GenerateParams>(
    defaultGenerateParams
  );
  const [file, setFile] = useState<File | undefined>();

  const resetAll = useCallback(() => {
    setFormErrors([]);
    setGenerateParams(defaultGenerateParams);
    setFile(undefined);
    setSuccess(false);
    setApiError(undefined);
    setIsSubmitting(false);
  }, [setFormErrors, setFile, setApiError, setSuccess, setIsSubmitting]);

  const handleSubmitImport = () => {
    schemaImport
      .validate({ file }, { abortEarly: false })
      .then((validForm) => {
        setFormErrors([]);

          postGenerateFromTariffModel(validForm.file, generateParams)
              .then((response) => {
                  setSuccess(true);
                  FileDownload(response.file as any, response.fileName);
              })
              .catch((error: RequestError) => {
                  setApiError({ ...error, type: "api" });
              });
      })
      .catch((e) => setFormErrors(e.errors));
  };

  const disabled =
    !generateParams.primary &&
    !generateParams.secondary &&
    !generateParams.spendCaps;

  return (
    <React.Fragment>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <Page
        title="Generate Products"
        actions={
          success || apiError ? (
            <Button onClick={resetAll} variant="outlined" size="large">
              Back
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={handleSubmitImport}
              disabled={disabled}
            >
              Generate
            </Button>
          )
        }
      >
        <APIFetchingWrapper error={apiError} loading={isSubmitting}>
          {success ? (
            <CustomStatusChip
              title="Success! File download should start shortly."
              type="success"
            />
          ) : (
            <React.Fragment>
              <form>
                <FormFieldsWrapper>
                  <FileDrop
                    acceptedFileTypes={[".xls, .xlsx"]}
                    onAcceptedDrop={setFile}                    
                  />
                  <div>
                    <Checkbox
                      sx={{ padding: 0, marginRight: 0.5 }}
                      color="primary"
                      onChange={() =>
                        setGenerateParams({
                          ...generateParams,
                          primary: !generateParams.primary,
                        })
                      }
                      checked={generateParams.primary}
                    />
                    Primary Products
                  </div>
                  <div>
                    <Checkbox
                      sx={{ padding: 0, marginRight: 0.5 }}
                      color="primary"
                      onChange={() =>
                        setGenerateParams({
                          ...generateParams,
                          secondary: !generateParams.secondary,
                        })
                      }
                      checked={generateParams.secondary}
                    />
                    Secondary Products
                  </div>
                  <div>
                    <Checkbox
                      color="primary"
                      sx={{ padding: 0, marginRight: 0.5 }}
                      onChange={() =>
                        setGenerateParams({
                          ...generateParams,
                          spendCaps: !generateParams.spendCaps,
                        })
                      }
                      checked={generateParams.spendCaps}
                    />
                    Spend Caps
                  </div>
                </FormFieldsWrapper>
              </form>

              {formErrors.length > 0 && (
                <CustomStatusChip
                  title="Please fix the following field errors and try again:"
                  message={
                    <List>
                      {formErrors.map((error, i) => (
                        <ListItem key={i} disableGutters>
                          {error}
                        </ListItem>
                      ))}
                    </List>
                  }
                  type="error"
                />
              )}
            </React.Fragment>
          )}
        </APIFetchingWrapper>
      </Page>
    </React.Fragment>
  );
};
