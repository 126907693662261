import {
  CHANGE_TAB,
  DetailPartnersActionTypes,
  DetailPartnersState,
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
} from "./types";

const initialState: DetailPartnersState = {
  items: {},
  fetchingIds: [],
  errors: [],
  mainTabIndex: 0,
  oocTabIndex: 0,
};

export function detailPartnersReducer(
  state = initialState,
  action: DetailPartnersActionTypes
): DetailPartnersState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        fetchingIds: state.fetchingIds.concat(action.payload.partnerId),
        errors: state.errors.filter(
          (error) => error.id !== action.payload.partnerId
        ),
      };
    case SET_API_ERROR:
      return {
        ...state,
        fetchingIds: state.fetchingIds.filter((id) => id !== action.payload.id),
        errors: state.errors.concat(action.payload),
      };
    case SET_DATA:
      return {
        ...state,
        fetchingIds: state.fetchingIds.filter(
          (id) => id !== action.payload.detailPartner.partnerId
        ),
        errors: state.errors.filter(
          (error) => error.id !== action.payload.detailPartner.partnerId
        ),
        items: {
          ...state.items,
          [action.payload.detailPartner.partnerId]:
            action.payload.detailPartner,
        },
        oocTabIndex:
          state.oocTabIndex === 0
            ? action.payload.detailPartner.partnerServiceProviders[0]
                ?.partnerServiceProviderId || state.oocTabIndex
            : state.oocTabIndex,
      };

    case CHANGE_TAB: {
      if (action.payload.tabName === "main") {
        return {
          ...state,
          mainTabIndex: action.payload.tabIndex,
        };
      } else {
        return {
          ...state,
          oocTabIndex: action.payload.tabIndex,
        };
      }
    }
    default:
      return state;
  }
}
