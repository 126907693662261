import { assetInterfaceReportReducer } from "./interfaceAssetReport/reducers";
import { assetReportReducer } from "./assetFinancialsReport/reducers";
import { bulkEditPartnerRateFormReducer } from "./bulkEditPartnerRateForm/reducers";
import { combineReducers } from "redux";
import { commentsReducer } from "./comments/reducers";
import { createAssetFormReducer } from "./createAssetForm/reducers";
import { createPartnerRateFormReducer } from "./createPartnerRateForm/reducers";
import { createPartnerServiceProviderFormReducer } from "./createPartnerServiceProviderForm/reducers";
import { createPrimaryProductFormReducer } from "./createPrimaryProductForm/reducers";
import { createProductRateFormReducer } from "./createProductRateForm/reducers";
import { createSecondaryProductFormReducer } from "./createSecondaryProductForm/reducers";
import { deferredCostReportReducer } from "./deferredCostReport/reducers";
import { detailAssetsReducer } from "./detailAssets/reducers";
import { detailPartnersReducer } from "./detailPartners/reducers";
import { detailProductsReducer } from "./detailProducts/reducers";
import { disconRecUpValueReportReducer } from "./disconRecUpValueReport/reducers";
import { editPartnerRateFormReducer } from "./editPartnerRateForm/reducers";
import { editPartnerServiceProviderFormReducer } from "./editPartnerServiceProviderForm/reducers";
import { editProductRateFormReducer } from "./editProductRateForm/reducers";
import { financialBreakdownReducer } from "./financialBreakdown/reducers";
import { formAssetProductChangeReducer } from "./formAssetProductChange/reducers";
import { formRecalculatePartnerRateReducer } from "./formRecalculatePartnerRate/reducers";
import { formUpdateProofsReducer } from "./formUpdateProofs/reducers";
import { importPaymentDataReducer } from "./importPaymentData/reducers";
import { outOfContractFormReducer } from "./outOfContractModalForm/reducers";
import { outstandingCommissionsReportReducer } from "./outstandingCommissionsReport/reducers";
import { partnerAdminInformationReportReducer } from "./partnerAdminInformationReport/reducers";
import { partnerRateAdjustmentReportReducer } from "./partnerRateAdjustmentReport/reducers";
import { PaymentDataReducer } from "./paymentData/reducers";
import { PaymentDataSummaryReducer } from "./paymentDataSummary/reducers";
import { permissionsReducer } from "./permissions/reducers";
import { portAndResignReportReducer } from "./portAndResignReport/reducers";
import { progressReportReducer } from "./progressReport/reducers";
import { queryAlesReducer } from "./queryAles/reducers";
import { queryAssetsReducer } from "./queryAssets/reducers";
import { queryPartnersReducer } from "./queryPartners/reducers";
import { queryProductsReducer } from "./queryProducts/reducers";
import { querySelfBillReducer } from "./querySelfBill/reducers";
import { uiReducer } from "./ui/reducers";
import { unraisedRevshareReportReducer } from "./unraisedRevshareReport/reducers";
import { uploadTemplateAmendmentsReducer } from "./uploadTemplateAmendments/reducers";
import { createOocRevenueShareReducer } from "./createOocRevenueShareForm/reducers";
import { editOocRevenueShareFormReducer } from "./editOocRevenueShareForm/reducers";
import { createPartnerNoteFormReducer } from "./createPartnerNoteForm/reducers";

export const rootReducer = combineReducers({
  assetInterfaceReport: assetInterfaceReportReducer,
  assetReport: assetReportReducer,
  bulkEditPartnerRateForm: bulkEditPartnerRateFormReducer,
  comments: commentsReducer,
  createAssetForm: createAssetFormReducer,
  createPartnerNoteForm: createPartnerNoteFormReducer,
  createPartnerRateForm: createPartnerRateFormReducer,
  createPartnerServiceProviderForm: createPartnerServiceProviderFormReducer,
  createPrimaryProductForm: createPrimaryProductFormReducer,
  createProductRateForm: createProductRateFormReducer,
  createSecondaryProductForm: createSecondaryProductFormReducer,
  createOocRevenueShareForm: createOocRevenueShareReducer,
  deferredCostReport: deferredCostReportReducer,
  detailAssets: detailAssetsReducer,
  detailPartners: detailPartnersReducer,
  detailProducts: detailProductsReducer,
  disconRecUpValueReport: disconRecUpValueReportReducer,
  editPartnerRateForm: editPartnerRateFormReducer,
  editPartnerServiceProviderForm: editPartnerServiceProviderFormReducer,
  editProductRateForm: editProductRateFormReducer,
  editOocRevenueShareForm: editOocRevenueShareFormReducer,
  financialBreakdown: financialBreakdownReducer,
  formAssetProductChange: formAssetProductChangeReducer,
  formRecalculatePartnerRate: formRecalculatePartnerRateReducer,
  formUpdateProofs: formUpdateProofsReducer,
  importPaymentData: importPaymentDataReducer,
  outOfContractForm: outOfContractFormReducer,
  outstandingCommissionsReport: outstandingCommissionsReportReducer,
  partnerAdminInformationReport: partnerAdminInformationReportReducer,
  partnerRateAdjustmentReport: partnerRateAdjustmentReportReducer,
  paymentData: PaymentDataReducer,
  paymentDataSummary: PaymentDataSummaryReducer,
  permissions: permissionsReducer,
  portAndResignReport: portAndResignReportReducer,
  progressReport: progressReportReducer,
  queryAles: queryAlesReducer,
  queryAssets: queryAssetsReducer,
  queryPartners: queryPartnersReducer,
  queryProducts: queryProductsReducer,
  querySelfBill: querySelfBillReducer,
  ui: uiReducer,
  unraisedRevshareReport: unraisedRevshareReportReducer,
  uploadTemplateAmendments: uploadTemplateAmendmentsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
