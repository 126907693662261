import { startOfDay } from "date-fns";
import { request } from "../..";
import { AssetStatus } from "../../../shared/enum/asset";
import { ServiceProvider } from "../../../shared/enum/invoice";
import { toDateString } from "../../../shared/helpers/dates";

export type GenerateUpfrontParams = {
  allServiceProviders: boolean;
  reference: string;
  serviceProviders: ServiceProvider[];
  startDate: Date;
  endDate: Date;
  assetStatuses: AssetStatus[];
  invoiceDate: Date;
  thirdPartyIds: string[];
  assetIds: number[];
};

//@NOTE - we can pass empty arrays to signal "select all" for fields with array type
export function postGenerateUpfront(params: GenerateUpfrontParams) {
  const resource = "invoice/upfront";
  const paramsWithDate = {
    ...params,
    startDate: toDateString(startOfDay(params.startDate)),
    endDate: toDateString(startOfDay(params.endDate)),
    invoiceDate: toDateString(startOfDay(params.invoiceDate)),
  };
  return request<void>(resource, {
    method: "POST",
    body: JSON.stringify(paramsWithDate),
  });
}
