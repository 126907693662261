import { DetailPartnerApiItem } from "../../api/partner";
import { RequestError } from "../../api/types";
import { REQUEST_DATA, SET_API_ERROR, SET_DATA } from "./types";

export function requestDetailPartnerData(partnerId: number) {
  return {
    type: REQUEST_DATA,
    payload: {
      partnerId,
    },
  };
}

export function setDetailPartnerData(data: DetailPartnerApiItem) {
  return {
    type: SET_DATA,
    payload: {
      detailPartner: data,
    },
  };
}

export function setDetailPartnerRequestError(
  error: { id: number } & RequestError
) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}
