import { DetailPartnerApiItem } from "../../api/partner";
import { RequestError } from "../../api/types";

export const SET_DATA = "detailPartners.SET_DATA";
export const REQUEST_DATA = "detailPartners.REQUEST_DATA";
export const SET_API_ERROR = "detailPartners.SET_API_ERROR";
export const CHANGE_TAB = "detailPartners.CHANGE_TAB";

export type DetailPartnerTabName = "main" | "ooc";

export interface DetailPartnersState {
  items: {
    [partnerId: number]: DetailPartnerApiItem;
  };
  fetchingIds: number[];
  errors: ({ id: number } & RequestError)[];
  mainTabIndex: number;
  oocTabIndex: number;
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    partnerId: number;
  };
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    detailPartner: DetailPartnerApiItem;
  };
}

interface ChangeTabAction {
  type: typeof CHANGE_TAB;
  payload: { tabName: DetailPartnerTabName; tabIndex: number };
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: { id: number } & RequestError;
}

export type DetailPartnersActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetAPIErrorAction
  | ChangeTabAction;
