import { stringify } from "query-string";
import { requestFileFromFile } from "../..";
import { GenerateParams } from "../../../views/products/generate";

export function postGenerateFromTariffModel(
  file: File,
  generateParams: GenerateParams
) {
  let body = new FormData();
  body.append("file", file);

    return requestFileFromFile(
    `product-csv/generate-from-tariff-model?${stringify(generateParams)}`,
    { method: "POST", body },
    false
  );
}
