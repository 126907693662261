import { makeStyles } from "tss-react/mui";

import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailPartnerApiItem, PartnerRate } from "../../../api/partner";
import { EditableField } from "../../../shared/components/EditableField";
import { Panel } from "../../../shared/components/tab_panels/Panel";
import { TabPanelWrapper } from "../../../shared/components/tab_panels/TabPanelWrapper";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { PartnerStatus, PaymentTerms } from "../../../shared/enum/invoice";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { formatDecimal } from "../../../shared/helpers/numbers";
import {
  CHANGE_TAB,
  DetailPartnersState,
} from "../../../store/detailPartners/types";
import { RootState } from "../../../store/rootReducer";
import { CreatePartnerRateModal } from "./CreatePartnerRateModal";
import { EditPartnerRateModal } from "./EditPartnerRateModal";
import { BulkEditPartnerRateModal } from "./BulkEditPartnerRateModal";
import { CreatePartnerServiceProviderModal } from "./CreatePartnerServiceProviderModal";
import { LinkedServiceProvidersTable } from "./LinkedServiceProvidersTable";
import { OutOfContractTable } from "./OutOfContractTable";
import { CreatePartnerNoteModal } from "./CreatePartnerNoteModal";
import { CommentsTable } from "./CommentsTable";

type Props = {
  data: DetailPartnerApiItem;
};

const useStyles = makeStyles()(() => ({
  serviceProviderRow: {
    backgroundColor: "#fafafa",
    cursor: "pointer",
  },
  serviceProviderCell: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  rates: {
    "&:nth-of-type(even)": {
      backgroundColor: "#fcfcfc",
    },
  },
  cell: {
    width: "11.1111%",
    textAlign: "right",
    whiteSpace: "nowrap",
  },
}));

interface ICustomCheckboxProps {
  row: PartnerRate;
  sp: ServiceProvider;
}

type ICheckedState = { [key: string]: number[] };

export const PartnerCard = ({ data }: Props) => {
  const initialCheckedState: ICheckedState = {
    EE: [],
    O2: [],
    Vodafone: [],
  };

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [expandedProviders, setExpandedProviders] = useState<ServiceProvider[]>(
    []
  );
  const [checkedState, setCheckedState] = useState<ICheckedState>(
    initialCheckedState
  );

  const { mainTabIndex } = useSelector<RootState, DetailPartnersState>(
    (state: RootState) => state.detailPartners
  );

  const a11yProps = (index: string) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, tabIndex: number) => {
    dispatch({
      type: CHANGE_TAB,
      payload: { tabIndex, tabName: "main" },
    });
  };

  const handleOpen = (serviceProvider: ServiceProvider) => {
    if (expandedProviders.includes(serviceProvider)) {
      setExpandedProviders(
        expandedProviders.filter((sp) => sp !== serviceProvider)
      );
    } else {
      setExpandedProviders([...expandedProviders, serviceProvider]);
    }
  };

  const updateChecked = (
    row: PartnerRate,
    sp: ServiceProvider,
    checked: boolean
  ) => {
    setCheckedState((prevState) => {
      const newState = { ...prevState };
      for (const serviceProvider of Object.keys(prevState)) {
        if (sp === serviceProvider) {
          if (checked) {
            newState[serviceProvider] = [
              ...newState[serviceProvider],
              row.partnerRateAdjustmentId,
            ];
          } else {
            newState[serviceProvider] = [
              ...newState[serviceProvider].filter(
                (i) => i !== row.partnerRateAdjustmentId
              ),
            ];
          }
        }
      }

      return newState;
    });
  };

  const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({ row, sp }) => {
    if (new Date(row.adjEndDate) < new Date()) return null;
    return (
      <Checkbox
        color="primary"
        data-cy={`filter-item-checkbox-${row.partnerRateAdjustmentId}`}
        checked={checkedState[sp].includes(row.partnerRateAdjustmentId)}
        onChange={(e) => {
          updateChecked(row, sp, e.target.checked);
        }}
      />
    );
  };

  return (
    <Box>
      <TabPanelWrapper>
        <Tabs
          onChange={handleTabChange}
          aria-label="view partner tabs"
          indicatorColor="primary"
          value={mainTabIndex}
          variant="fullWidth"
        >
          <Tab label="General" {...a11yProps} />
          <Tab label="Revshare Rate Adjustments" {...a11yProps} />
          <Tab label="Service Providers" {...a11yProps} />
          <Tab label="Comments" {...a11yProps} />
        </Tabs>
        <Divider />
        <Panel value={mainTabIndex} index={0}>
          <List>
            <ListItem divider>
              <EditableField label="Partner ID" value={data.partnerId} />
            </ListItem>
            <ListItem divider>
              <EditableField label="Partner Name" value={data.partnerName} />
            </ListItem>
            <ListItem divider>
              <EditableField label="Partner Code" value={data.thirdPartyId} />
            </ListItem>
            <ListItem divider>
              <EditableField
                label="Partner Status"
                value={PartnerStatus[data.partnerStatus]}
              />
            </ListItem>
            <ListItem divider>
              <EditableField
                label="Payment Terms"
                value={PaymentTerms[data.paymentTerms]}
              />
            </ListItem>
            <ListItem divider>
              <EditableField label="BDM" value={data.bdm} />
            </ListItem>
            <ListItem divider>
              <EditableField
                label="Network Billed Partner Manager"
                value={data.ibdm}
              />
            </ListItem>
            <ListItem divider>
              <EditableField label="Region" value={data.region} />
            </ListItem>
            <ListItem>
              <EditableField label="Territory" value={data.territory} />
            </ListItem>
          </List>
        </Panel>
        <Panel value={mainTabIndex} index={1}>
          <Table aria-label="partner-rates">
            <TableBody>
              {serviceProviders.map((sp) => {
                const rates = data.rates.filter(
                  (r) => r.serviceProviderId === getServiceProviderId(sp)
                );
                const open = expandedProviders.includes(sp);

                if (rates.length > 0) {
                  return (
                    <>
                      <TableRow
                        className={classes.serviceProviderRow}
                        onClick={() => handleOpen(sp)}
                      >
                        <TableCell
                          className={classes.serviceProviderCell}
                          colSpan={6}
                        >
                          <Typography variant="h4">{sp}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton aria-label="expand row" size="small">
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          style={{ padding: 0, border: 0 }}
                        >
                          <Collapse
                            in={open}
                            timeout="auto"
                            unmountOnExit
                            collapsedSize={0}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell />
                                  <TableCell
                                    className={classes.cell}
                                    style={{ textAlign: "left" }}
                                  >
                                    <b>Tier</b>
                                  </TableCell>
                                  <TableCell
                                    className={classes.cell}
                                    style={{ textAlign: "left" }}
                                  >
                                    <b>Product Type</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Received Rate</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Standard Rate</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Start Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>End Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adjustment</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adj. Start Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adj. End Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {checkedState[sp].length > 0 ? (
                                      <BulkEditPartnerRateModal
                                        partnerId={data.partnerId}
                                        partnerRateAdjustmentIds={
                                          checkedState[sp]
                                        }
                                      />
                                    ) : (
                                      <CreatePartnerRateModal
                                        partnerId={data.partnerId}
                                        serviceProvider={sp}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                                {rates.map((r, i) => {
                                  const disabled =
                                    new Date(r.adjEndDate) < new Date();
                                  const columnStyle = {
                                    fontWeight: disabled ? 200 : 400,
                                  };

                                  return (
                                    <TableRow className={classes.rates} key={i}>
                                      <TableCell>
                                        <CustomCheckbox row={r} sp={sp} />
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={{
                                          ...columnStyle,
                                          textAlign: "left",
                                        }}
                                      >
                                        {r.tier}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={{
                                          ...columnStyle,
                                          textAlign: "left",
                                        }}
                                      >
                                        {r.productType}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatDecimal(
                                          r.receivedBillRate.toString()
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatDecimal(
                                          r.standardRate.toString()
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatISOForDisplay(r.startDate)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatISOForDisplay(r.endDate)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatDecimal(r.adjustment.toString())}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatISOForDisplay(r.adjStartDate)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatISOForDisplay(r.adjEndDate)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        <EditPartnerRateModal
                                          partnerId={data.partnerId}
                                          partnerRate={r}
                                          disabled={disabled}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                } else {
                  return (
                    <>
                      <TableRow
                        className={classes.serviceProviderRow}
                        onClick={() => handleOpen(sp)}
                      >
                        <TableCell
                          className={classes.serviceProviderCell}
                          colSpan={6}
                        >
                          <Typography variant="h4">{sp}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton aria-label="expand row" size="small">
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          style={{ padding: 0, border: 0 }}
                        >
                          <Collapse
                            in={open}
                            timeout="auto"
                            unmountOnExit
                            collapsedSize={0}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    className={classes.cell}
                                    style={{ textAlign: "left" }}
                                  >
                                    <b>Tier</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Received Rate</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Standard Rate</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Start Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>End Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adjustment</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adj. Start Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adj. End Date</b>
                                  </TableCell>
                                  <TableCell
                                    className={classes.cell}
                                  ></TableCell>
                                  <TableCell className={classes.cell}>
                                    <CreatePartnerRateModal
                                      partnerId={data.partnerId}
                                      serviceProvider={sp}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                }
              })}
            </TableBody>
          </Table>
        </Panel>
        <Panel value={mainTabIndex} index={2}>
          <Box p={2}>
            <Box mb={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                mb={1}
                mt={1.5}
                pl={1}
              >
                <Typography variant="h3">Linked Service Providers</Typography>
                <CreatePartnerServiceProviderModal partnerId={data.partnerId} />
              </Box>
              <LinkedServiceProvidersTable
                data={data.partnerServiceProviders}
              />
            </Box>
            <OutOfContractTable data={data} />
          </Box>
        </Panel>
        <Panel value={mainTabIndex} index={3}>
          <Box p={2}>
            <Box mb={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                mb={1}
                mt={1.5}
                pl={1}
              >
                <Typography variant="h3">Comments</Typography>
                <CreatePartnerNoteModal partnerId={data.partnerId} />
              </Box>
            </Box>
            <CommentsTable data={data.notes} />
          </Box>
        </Panel>
      </TabPanelWrapper>
    </Box>
  );
};
