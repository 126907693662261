import { RequestError } from "./types";
import { UserManager } from "oidc-client";
import authConfig from "../authConfig";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface RequestFileResult {
  file: Blob;
  fileName: string;
}

async function getAccessToken() {
  const userManager = new UserManager({
    ...authConfig,
    client_id: authConfig.clientId,
    automaticSilentRenew: true,
  });
  const user = await userManager.getUser();

  return user?.access_token;
}

function isJson(input: any) {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
}

function getFileName(header: string | null) {
  if (!header) return "";

  const fileNameString = header.slice(header.indexOf("filename"));
  const fileName = fileNameString.slice(
    fileNameString.indexOf("=") + 1,
    fileNameString.indexOf(";")
  );

  return fileName;
}

function parseJSON(
  response: Response
): Promise<{
  status: number;
  ok: boolean;
  data: any;
}> {
  return new Promise((resolve) =>
    response.text().then((data) =>
      resolve({
        status: response.status,
        ok: response.ok,
        data: isJson(data) ? JSON.parse(data) : data,
      })
    )
  );
}

// @TODO - This will overrite any headers passed by consumers.  Need to loop over passed options.headers and append
// to newly created Headers, before merging back in with other passed options.  Need to handle a "blank" content-type
// for multipart file posting.  Setting explicitly as "undefined" will not work.  Not fun while also keeping Typescript happy :-(
// Might be better to seperate out the JSON and/or file handling functionality to their own services, but then we're duplicating...
export function request<T>(
  resource: string,
  options?: RequestInit,
  json: boolean = true
): Promise<T> {
  return new Promise(async (resolve, reject) => {
    if (!API_BASE_URL) {
      return reject(
        "REACT_APP_API_BASE_URL was not present in the environment upon build"
      );
    }

    const accessToken = await getAccessToken();

    const headers = new Headers();
    headers.append("Authorization", "Bearer " + accessToken);

    if (json) {
      headers.append("Content-Type", "application/json");
    }

    fetch(`${API_BASE_URL}${resource.replace(/^\/+/g, "")}`, {
      ...options,
      headers: headers,
    })
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response.data as T);
        } else {
          let error: RequestError = {
            status: response.status,
            type: "api",
            message: response.data,
          };
          return reject(error);
        }
      })
      .catch((e) => {
        console.error(e);
        let error: RequestError = {
          type: "network",
          message: e.message,
        };
        return reject(error);
      });
  });
}

export function requestFileFromFile(
    resource: string,
    options?: RequestInit,
    json: boolean = true
): Promise<RequestFileResult> {
    return new Promise(async (resolve, reject) => {
        if (!API_BASE_URL) {
            return reject(
                "REACT_APP_API_BASE_URL was not present in the environment upon build"
            );
        }
        const accessToken = await getAccessToken();

        const headers = new Headers();
        headers.append("Authorization", "Bearer " + accessToken);

        if (json) {
            headers.append("Content-Type", "application/json");
        }

        fetch(`${API_BASE_URL}${resource.replace(/^\/+/g, "")}`, {
            ...options,
            headers: headers,
        })
            .then((response) => {
                if (response.ok) {
                    const fileName = getFileName(
                        response.headers.get("Content-Disposition")
                    );
                    response.blob().then((blob) => {
                        return resolve({
                            file: blob,
                            fileName,
                        });
                    });
                } else {
                    parseJSON(response).then((data) => {
                        let error: RequestError = {
                            status: data.status,
                            type: "api",
                            message: data.data,
                        };
                        return reject(error);
                    });
                }
            })
            .catch((e) => {
                console.error(e);
                let error: RequestError = {
                    type: "network",
                    message: e.message,
                };
                return reject(error);
            });
    });
}

export function requestFile(
  resource: string,
  options?: RequestInit
): Promise<RequestFileResult> {
  return new Promise(async (resolve, reject) => {
    if (!API_BASE_URL) {
      return reject(
        "REACT_APP_API_BASE_URL was not present in the environment upon build"
      );
    }

    const accessToken = await getAccessToken();

    const headers = new Headers();
    headers.append("Authorization", "Bearer " + accessToken);
    headers.append("content-type", "application/json");

    fetch(`${API_BASE_URL}${resource.replace(/^\/+/g, "")}`, {
      ...options,
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          const fileName = getFileName(
            response.headers.get("Content-Disposition")
          );
          response.blob().then((blob) => {
            return resolve({
              file: blob,
              fileName,
            });
          });
        } else {
          parseJSON(response).then((data) => {
            let error: RequestError = {
              status: data.status,
              type: "api",
              message: data.data,
            };
            return reject(error);
          });
        }
      })
      .catch((e) => {
        console.error(e);
        let error: RequestError = {
          type: "network",
          message: e.message,
        };
        return reject(error);
      });
  });
}
