import React from "react";
import { Typography } from "@mui/material";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ReviewSelfBill } from "./review";
import { GenerateRevshare } from "./generate/revshare";
import { GenerateUpfront } from "./generate/upfront";

export const SelfBill = () => {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Typography variant="h2" gutterBottom>
        Self Bill
      </Typography>
      <Switch>
        <Route path={`${path}/generate-revshare`}>
          <GenerateRevshare />
        </Route>
        <Route path={`${path}/generate-upfront`}>
          <GenerateUpfront />
        </Route>
        <Route path={`${path}/review`}>
          <ReviewSelfBill />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
