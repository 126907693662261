import { request } from "..";
import { ServiceProvider } from "../../shared/enum/serviceProvider";
import {
  ProductClass,
  ProductCustomerType,
  ProductVariant,
} from "../../shared/enum/product";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { CreatePrimaryProductFormValues } from "../../store/createPrimaryProductForm/types";
import {
  BusinessResponseObject,
  PaginatedResult,
  RequestError,
  TableAPIRequestParams,
} from "../types";

type BaseProductApiItem = {
  description: string;
  productId: number;
  parentId: number;
  productCode: string;
  productClass: ProductClass;
  productType: string;
  serviceProvider: ServiceProvider;
  productVariant: ProductVariant;
  duration: number;
  productGroup: string;
  productGroupId: number;
  customerType: ProductCustomerType;
};

export type QueryProductApiItem = BaseProductApiItem & {
  name: string;
  serviceProviderProductId: string;
};

export type ReadProductApiItem = BaseProductApiItem & {
  productName: string;
  serviceProviderProductId: string;
};

export type NewProductApiItem = {
  productId: number;
  parentId: number;
  productCode: string;
  customerType: ProductCustomerType;
  duration: number;
  description: string;
  name: string;
};

export type QueryProductFilterParams = {
  serviceProvider: ServiceProvider[];
  productVariant: (string | null)[];
  duration: (string | null)[];
  productCode: (string | null)[];
  productClass: (string | null)[];
  productType: (string | null)[];
  name: (string | null)[];
  productId: number[];
};

export type QueryProductRequestParams = {
  tableParams: TableAPIRequestParams<QueryProductApiItem>;
  filterParams: Partial<QueryProductFilterParams>;
};

export type UpdateProductApiItem = {
  productId: number;
  productName: string;
  productCode: string;
  description: string;
  duration: number;
  productType?: string;
  productTypeId?: number;
  productGroupId?: number;
  productGroup?: string;
  serviceProvider?: ServiceProvider;
  serviceProviderId: number;
  customerType: ProductCustomerType;
};

export function postAddProduct(params: CreatePrimaryProductFormValues) {
  return request<BusinessResponseObject<NewProductApiItem>>(
    "product/primary-product/add",
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}

export function getProductById(
  productId: number
): Promise<QueryProductApiItem> {
  return new Promise((resolve, reject) => {
    getQueryProduct({
      tableParams: {
        pageSize: 1,
        pageNumber: 1,
        sortBy: "productId",
        sortDirection: 0,
      },
      filterParams: { productId: [productId] },
    }).then((tableData) => {
      const item = tableData.items.find((item) => item.productId === productId);
      if (item) {
        resolve(item);
      } else {
        const error: RequestError = {
          type: "api",
          message: `Product with id ${productId} not found in API response`,
        };
        reject(error);
      }
    });
  });
}

export function getQueryProduct({
  tableParams,
  filterParams,
}: QueryProductRequestParams) {
  const tableParamsQS = stringifyTableParams(tableParams);
  const filterParamsQS = stringifyFilterParams(filterParams);

  return request<PaginatedResult<QueryProductApiItem>>(
    `product${combine([tableParamsQS, filterParamsQS])}`
  );
}

export function putUpdateProduct(id: number, updates: UpdateProductApiItem) {
  return request<void>(`product/${id}`, {
    method: "PUT",
    body: JSON.stringify(updates),
  });
}
